import {Component, Input, OnInit} from '@angular/core';

import {NbMenuService, NbSidebarService} from '@nebular/theme';
import {UserService} from '../../../@core/data/users.service';
import {NbAuthService} from "@nebular/auth";
import {UsersService} from "../../../pages/users/services/users.service";

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {


    @Input() position = 'normal';

    user: any;

    userMenu = [{title: 'Profile',}, {title: 'Log out'}];

    constructor(private sidebarService: NbSidebarService,
                private menuService: NbMenuService,
                private usersService: UsersService) {
    }

    ngOnInit() {
        this.usersService.getCurrentUser().subscribe(response => {
            this.user = response.user;
            localStorage.setItem("currentUser", this.user);
            // console.log(this.user);
        });
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        return false;
    }

    goToHome() {
        this.menuService.navigateHome();
    }
}
