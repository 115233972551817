import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {NbAuthService} from "@nebular/auth";


@Injectable()
export class AuthService {
  httpOptions = {};

  public  token;
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }


  login(user) {
    let url = this.apiUrl + 'login';
    return this.http.post(url, user).map((data) => {




      return data;
    });
  }

  logout() {
    return this.http.post(this.apiUrl + 'logout', null, this.httpOptions).map(response => {
      return response;
    });
  }
}
