import {Component, OnInit} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {SalesService} from "./sales.sheet.service";

@Component({
  selector: 'sales-sheet',
  templateUrl: 'sales-sheet.component.html',
  styleUrls: ['sales-sheet.component.scss'],
})
export class SalesSheetComponent implements OnInit {
  settings = {
    mode: 'inline',
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'ID',
        editable: false,
        addable: false,
      },
      client_order_number: {
        title: 'CLIENT ORDER NUMBER',
        type: 'string',
      },
      developer: {
        title: 'DEVELOPER',
        type: 'string',
      },
      site: {
        title: 'Site',
        type: 'string',
      },
      plot: {
        title: 'PLOT',
        type: 'text',
      },
      house_type: {
        title: 'HOUSE TYPE',
        type: 'text',
      },
      rooms: {
        title: 'Rooms',
        type: 'text',
      },
      net_m2: {
        title: 'NETT M2',
        type: 'text',
      },
      carpet: {
        title: 'CARPET',
        type: 'text',
      },
      underlay: {
        title: "UNDERLAY",
        type: 'text',
      },
      matwell: {
        title: "MATWELL",
        type: "text",
      },
      lvt: {
        title: 'LVT',
        type: 'text',
      },
      tile_size: {
        title: 'TITLE SIZE',
        type: 'text',
      },
      stripping: {
        title: 'STRIPPING',
        type: 'text',
      },
      angle: {
        title: 'ANGLE',
        type: 'text',
      },
      vinyl: {
        title: 'VINYL',
        type: 'text'
      },
      wood: {
        title: 'WOOD',
        type: 'text'
      },
      value: {
        title: 'VALUE',
        type: 'text'
      }, on_order: {
        title: 'ON ORDER',
        type: 'text'
      },

      screed_date: {
        title: 'SCREED DATE',
        type: 'text'
      },
      screeded_by: {
        title: 'SCREEDED BY',
        type: 'text'
      }, fitted_date: {
        title: 'FITTED DATE',
        type: 'text'
      },
      fitter: {
        title: 'FITTER',
        type: 'text'
      },
      invoice: {
        title: 'INVOICE',
        type: 'text'
      }, sign_off: {
        title: 'SIGN OFF',
        type: 'text'
      },
      self_billing_date_signed: {
        title: 'SELF BILLING DATE SIGNED',
        type: 'text'
      },
      prep: {
        title: 'PREP',
        type: 'text'
      }, prep_inv_date: {
        title: 'PREP INV DATE',
        type: 'text'
      },
      fit: {
        title: 'FIT',
        type: 'text'
      },
      fit_inv_date: {
        title: 'FIT INV DATE',
        type: 'text'
      },
      protection: {
        title: 'PROTECTION',
        type: 'text'
      },
      protect_inv_date: {
        title: 'PROTECT INV DATE',
        type: 'text'
      },
      misc: {
        title: 'MISC',
        type: 'text'
      },
      misc_inv_details: {
        title: 'MISC INV DETAILS',
        type: 'text'
      },
      misc_inv_date: {
        title: 'MISC INV DATE',
        type: 'text'
      },
        gripper: {
        title: 'GRIPPER',
        type: 'text'
      },
    },
  };



  messages = [];
  source: LocalDataSource = new LocalDataSource();

  ngOnInit() {
    this.service.all().subscribe(result => {
      this.source = result.data;

console.log(this.source);


      
    });
  }

  constructor(private service: SalesService) {

    
  }




  onDeleteConfirm(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      let user = event.data;
      if (user) {
        this.service.removeSales(user).subscribe((result) => {
          if (result.success) {
            event.confirm.resolve();
          }
        });
      }
    } else {
      event.confirm.reject();
    }
  }

  onSaveConfirm(event): void {
    let sales = event.newData;
    if (sales) {
      this.service.updateSales(sales).subscribe((response) => {
        if (response.success) {
          let newUser = event.newData;
          newUser.id = response.sales.id;
          event.confirm.resolve(newUser);
        }
      });
    }
  }

  onCreateConfirm(event): void {
    let user = event.newData;
    if (user) {
      this.service.add(user).subscribe((result) => {
        if (result.success) {
          this.messages = [];
          event.confirm.resolve(result.sales);
        }
      }, errorResponse => {
        this.messages = [];
        let errors = errorResponse.error.errors;
        let keys = Object.keys(errors);
        for (let key of keys) {
          let error = errors[key];
          for (let val of error) {
            this.messages.push(val);
          }
        }
      });
    }
  }
}
