import {Component, OnInit} from '@angular/core';
import {LocalDataSource} from "ng2-smart-table";
import {RoleService} from "./services/role.service";
import {OptionsComponent} from "./options/options.component";

@Component({
    selector: 'ngx-role',
    templateUrl: './role.component.html',
    styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
    messages = [];
    source: LocalDataSource = new LocalDataSource();
    settings = {
        mode: 'inline',
        actions: {
            add: false,
            edit: false,
            delete: false,
        },
        add: {
            addButtonContent: '<i class="nb-plus"></i>',
            createButtonContent: '<i class="nb-checkmark"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
            confirmCreate: true,
        },
        edit: {
            editButtonContent: '<i class="nb-edit"></i>',
            saveButtonContent: '<i class="nb-checkmark"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
            confirmSave: true,
        },
        delete: {
            deleteButtonContent: '<i class="nb-trash"></i>',
            confirmDelete: true,
        },
        columns: {
            id: {title: 'id', editable: false, addable: false},
            name: {title: 'name', type: 'string'},
            description: {title: 'description', type: 'string'},
            button: {
                title: 'Click me',
                type: 'custom',
                renderComponent: OptionsComponent,
            },
        }
    };

    ngOnInit() {
        this.service.all().subscribe(result => {
            this.source = result.roles;
        });
        let roles = localStorage.getItem("actions");
        if (roles) {
            roles = JSON.parse(roles);
            this.settings.actions.delete = roles.indexOf("roles_delete") >= 0;
            this.settings.actions.edit = roles.indexOf("roles_update") >= 0;
            this.settings.actions.add = roles.indexOf("roles_add") >= 0;
        }
    }

    constructor(private service: RoleService) {
    }

    onDeleteConfirm(event) {
        if (window.confirm('Are you sure you want to delete?')) {
            let role = event.data;
            if (role) {
                this.service.removeRecord(role).subscribe((result) => {
                    if (result.success) {
                        event.confirm.resolve();
                    }
                });
            }
        } else {
            event.confirm.reject();
        }
    }

    onSaveConfirm(event): void {
        let roles = event.newData;
        if (roles) {
            this.service.updateRecord(roles).subscribe((response) => {
                if (response.success) {
                    let newRecord = event.newData;
                    newRecord.id = response.record.id;
                    event.confirm.resolve(newRecord);
                }
            });
        }
    }

    onCreateConfirm(event): void {
        let role = event.newData;
        if (role) {
            this.service.add(role).subscribe((result) => {
                if (result.success) {
                    this.messages = [];
                    event.confirm.resolve(result.record);
                }
            }, errorResponse => {
                this.messages = [];
                let errors = errorResponse.error.errors;
                let keys = Object.keys(errors);
                for (let key of keys) {
                    let error = errors[key];
                    for (let val of error) {
                        this.messages.push(val);
                    }
                }
            });
        }
    }
}
