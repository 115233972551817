import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../user';
import {environment} from "../../../../environments/environment";
import {NbAuthService} from "@nebular/auth";
import {Router} from "@angular/router";

const httpOptions = {
  // headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class UsersService {
  httpOptions = {};

  constructor(private http: HttpClient, private router: Router) {
  }

  getHeader() {
    let token = localStorage.getItem('auth.token');
    if (typeof token != 'undefined' && token != null) {
      this.httpOptions = {
        headers: new HttpHeaders({Accpet: 'application/json', Authorization: 'Bearer ' + token})
      }
    } else {
     // localStorage.clear();
     }
  }

  // private instance variable to hold base url
  private url = environment.apiUrl;
  private apiUrl = environment.apiUrl + 'user';

  all(type = 'general') {
    this.getHeader();
    let url = this.apiUrl + '?type=' + type;
    return this.http.get(url, this.httpOptions).map((data: any) => {
      return data;
    });
  }

  counts() {
    this.getHeader();
    return this.http.get(this.url + 'users-numbers', this.httpOptions).map((data: any) => {
      return data;
    });
  }


  add(user: User) {
    this.getHeader();

    return this.http.post(this.apiUrl, user, this.httpOptions)
      .map((data: any) => {
        return data
      });
  }


  getCurrentUser() {
    this.getHeader();

    const url = `${this.apiUrl}/current`;
    return this.http.get(url, this.httpOptions).map((data: any) => {
      return data;
    });
  }

  updateUser(user: User | number) {
    this.getHeader();

    const id = typeof user === 'number' ? user : user.id;
    const url = `${this.apiUrl}/${id}`;
    return this.http.put(url, user, this.httpOptions).map((data: any) => {
      return data;
    });
  }


  removeUser(user: User | number) {
    this.getHeader();

    const id = typeof user === 'number' ? user : user.id;
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<User[]>(url, this.httpOptions).map((data: any) => {
      return data;
    });
  }

  profile(user) {
    this.getHeader();
    let url = this.apiUrl + '/profile';
    return this.http.post(url, user, this.httpOptions).map((data: any) => {
      return data;
    });
  }

  filterUsers() {
    this.getHeader();
    return this.http.get(this.apiUrl + '/filter', this.httpOptions);
  }
}
