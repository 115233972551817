import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  submitted = false;
  user = {};
  showMessages = {success: false, error: false};
  messages = [];
  errors = [];

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
  }

  login() {
    this.submitted = true;
    this.authService.login(this.user).subscribe(response => {
      this.submitted = false;
      let token = response['token'];
      if (token && token != null && token != '') {
        localStorage.setItem('auth.token', token);
        this.errors = [];
        this.messages.push('Logged Successfully!');
        this.showMessages.success = true;
        this.router.navigateByUrl('/pages/sales-sheet');
      }
    }, error => {
      this.submitted = false;
      console.log('Here');
      this.showMessages.error = true;
      this.messages = [];
      this.errors.push('Something went wrong!');
    });
  }
}
