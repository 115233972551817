import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from '../../../../environments/environment';
import {Observable} from "rxjs/Rx";
import {Role} from "../role";
import {NbAuthService} from "@nebular/auth";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  // token: string = '';
  public httpOptions = {};
  private apiUrl = environment.apiUrl + 'roles';

  constructor(private http: HttpClient, private router: Router) {
  }
  getHeader() {
    let token = localStorage.getItem('auth.token');
    if (typeof token != 'undefined' && token != null) {
      this.httpOptions = {
        headers: new HttpHeaders({Accpet: 'application/json', Authorization: 'Bearer ' + token})
      }
    } else {
    //  localStorage.clear();
    }
  }

  menu() {
    this.getHeader();
    return this.http.get(this.apiUrl + '/menu', this.httpOptions).map((data: any) => {
      return data;
    });
  }

  all() {
    this.getHeader();
    return this.http.get(this.apiUrl, this.httpOptions).map((data: any) => {
      return data;
    });
  }

  add(record) {
    this.getHeader();
    return this.http.post(this.apiUrl, record)
      .map((data: any) => {
        return data
      });
  }


  actions(id) {
    this.getHeader();
    const url = `${this.apiUrl}/${id}/actions`;
    return this.http.get(url, this.httpOptions).map((data: any) => {
      return data;
    });
  }

  updateRecord(record: Role | number) {
    this.getHeader();
    const id = typeof record === 'number' ? record : record.id;
    const url = `${this.apiUrl}/${id}`;
    return this.http.put(url, record, this.httpOptions).map((data: any) => {
      return data;
    });
  }


  removeRecord(record: Role | number) {
    this.getHeader();
    const id = typeof record === 'number' ? record : record.id;
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete(url, this.httpOptions).map((data: any) => {
      return data;
    });
  }

  saveActions(items, id) {
    this.getHeader();
    const url = `${this.apiUrl}/${id}/actions`;
    return this.http.post(url, {items: items}, this.httpOptions).map((data: any) => {
      return data;
    });
  }
}
