import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbAuthModule, NbAuthJWTToken, NbPasswordAuthStrategy} from '@nebular/auth';
import {NbSecurityModule, NbRoleProvider} from '@nebular/security';
import {of as observableOf} from 'rxjs';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {DataModule} from './data/data.module';
import {AnalyticsService} from './utils/analytics.service';
import {environment} from '../../environments/environment.prod';

import { SmartTableDatePickerComponent } from '../@theme/components/smart-table-date-picker-component/smart-table-date-picker.components';


const socialLinks = [
  {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'socicon-github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'socicon-facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'socicon-twitter',
  },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...DataModule.forRoot().providers,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        token: {
          class: NbAuthJWTToken,
          key: 'token', // this parameter tells where to look for the token
        },
        baseEndpoint: environment.publicUrl,
        login: {
          method: 'post',
          endpoint: 'api/--login',
          redirect: {
            success: '/pages',
            failure: null,
          },
        },
        register: {
          endpoint: 'api/register',
          redirect: {
            success: '/auth/--login',
            failure: null,
          },
        },
        logout: {
          endpoint: 'api/logout',
          method: 'post',
          redirect: {
            success: '/auth/--login',
            failure: null,
          },
        },
      }),
    ],
    forms: {
      login: {
        socialLinks: socialLinks,
        showMessages: {
          success: true,
        },
      },
      register: {
        socialLinks: socialLinks,
        showMessages: {
          success: true,
        },
      }, validation: {
        password: {
          required: true,
          minLength: 6,
          maxLength: 25,
        },
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
];

@NgModule({
  imports: [
    CommonModule,
    
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
  entryComponents: [
    SmartTableDatePickerComponent
  ]

})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
