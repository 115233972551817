﻿import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import {tap} from 'rxjs/internal/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: NbAuthService) {
  }

  canActivate() {
    let token = localStorage.getItem('auth.token');
    if (token) {
      return true;
    } else {
      this.router.navigate(['auth/login']);
      return false;
    }

  }
}
