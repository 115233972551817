import {Component, Input, OnInit} from '@angular/core';
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RoleService} from "../services/role.service";

@Component({
  templateUrl: './options.component.html',
})
export class OptionsComponent implements OnInit {

  public renderValue;
  selectedChange: any;
  modalReference: any;
  config = {
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 500
  };
  items = [];
  @Input() value;
  @Input() rowData: any;

  constructor(private modalService: NgbModal, private roleService: RoleService) {
  }

  ngOnInit() {
    this.renderValue = this.value;
  }


  open(content) {
    let row = this.rowData;
    this.roleService.actions(row.id).subscribe(response => {
      this.items = response;
    });

    this.modalReference = this.modalService.open(content, {
      beforeDismiss: () => {
        return false;
      }
    });

    this.modalReference.result.then((result) => {
      let items = this.items;
      console.log(items);
      this.roleService.saveActions(items, row.id).subscribe(response => {
        console.log(response);
      });
    }, (reason) => {
    });
  }

  onSelectedChange(values: any[]) {
  
   // this.selectedChange.emit(values);
  }

  closeModal() {
    this.modalReference.close();
  }


}
