import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '../../../../node_modules/@angular/forms';
import {UsersService} from '../users/services/users.service';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {NbAuthService} from "@nebular/auth";
import {Router} from "@angular/router";

@Component({
  selector: 'ngx-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup;
  name: FormControl;
  password: FormControl;
  dob: FormControl;
  telephone: FormControl;
  email: FormControl;
  password_confirmation: FormControl;
  messages = [];
  success = false;
  isResponse = false;
  selectedImage = null;
  uploadUrl = environment.apiUrl + 'user/upload';
  httpOptions = {};
  user = {name: '', telephone: '', dob: '', email: ''};
  url = '';

  constructor(private service: UsersService, private router: Router, private usersService: UsersService, private http: HttpClient, private authService: NbAuthService) {
    let serviceToken = authService.getToken();
    if (serviceToken['value']) {
      let token = serviceToken['value']['token'];
      this.httpOptions = {
        headers: new HttpHeaders({Accpet: 'application/json', Authorization: 'Bearer ' + token})
      };
    }

  }

  ngOnInit() {
    this.usersService.getCurrentUser().subscribe(response => {
      let user = response.user;
      this.name.setValue(user.name);
      this.email.setValue(user.email);
      this.telephone.setValue(user.telephone);
      this.dob.setValue(user.dob);
    }, error => {
     // localStorage.clear();
    });
    this.createControllers();
    this.createForm();
  }

  uploadFile(event) {
    this.selectedImage = <File>event.target.files[0];
    const fd = new FormData();
    fd.append("image", this.selectedImage, this.selectedImage.name);
    this.http.post(this.uploadUrl, fd, this.httpOptions).subscribe(response => {
      console.log(response);
    });
  }

  createControllers() {
    this.name = new FormControl(this.user.name, []);
    this.password = new FormControl("", []);
    this.password_confirmation = new FormControl("", []);
    this.telephone = new FormControl(this.user.telephone, []);
    this.email = new FormControl(this.user.email, []);
    this.dob = new FormControl(this.user.dob, []);
  }

  createForm() {
    this.profileForm = new FormGroup({
      name: this.name,
      password: this.password,
      password_confirmation: this.password_confirmation,
      telephone: this.telephone,
      email: this.email,
      dob: this.dob,
    });

  }

  save() {
    let user = {
      name: this.name.value,
      password: this.password.value,
      password_confirmation: this.password_confirmation.value,
      telephone: this.telephone.value,
      email: this.email.value,
      dob: this.dob.value,
    };

   
    this.service.profile(user).subscribe(response => {

      
      this.success = true;
      this.isResponse = true;
      this.messages = [];
      this.messages.push(response.success);
      
    }, errorResponse => {
      console.log(errorResponse);
      this.success = false;
      this.isResponse = true;
      this.messages = [];
      let errors = errorResponse.error.error;
      let keys = Object.keys(errors);
      for (let key of keys) {
        let error = errors[key];
        for (let val of error) {
          this.messages.push(val);
        }
      }
      
    }

    
    );
  }
}
