import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {NbAuthService} from "@nebular/auth";


@Injectable()
export class GeneralService {
    rates: any = [];
    chkrowid;
    mysalessheetids: any = [];
    mysalessheetdata: any = [];
    httpOptions = {};
    //site_variables
    site_fitter_name: any;
    site_site_name: any;
    //fitter_variable
    fitter_fitter_name: any;
    priceCompanyRateID: any;
    pricesiteRateID: any;
    pricesiteRateType: any;
    pricefitterRateID: any;
    pricefitterRateType: any;
    salesSheetData: any;
    private apiUrl = environment.apiUrl + 'table';
    private url = environment.apiUrl;

    constructor(private http: HttpClient, private authService: NbAuthService) {
    }

    getHeader() {
        let token = localStorage.getItem('auth.token');
        this.httpOptions = {
            headers: new HttpHeaders({Accpet: 'application/json', Authorization: 'Bearer ' + token})
        }
    }

    getUserDetails(userId) {
        this.getHeader();
        let url = this.url + "user/" + userId
        return this.http.get(url, this.httpOptions).map((data) => {
            return data;
        });
    }

    getRates() {
        this.getHeader();
        let url = this.url + "rates"
        return this.http.get(url, this.httpOptions).map((data) => {
            return data;
        });
    }

    get_company_rates(id) {
        this.getHeader();
        let url = this.url + "company_rates/" + id
        return this.http.get(url, this.httpOptions).map((data) => {
            return data;
        });
    }

    get_site_rates() {
        this.getHeader();
        let url = this.url + "site_rates/"
        return this.http.get(url, this.httpOptions).map((data) => {
            return data;
        });
    }

    get_fitter_rates() {
        this.getHeader();
        let url = this.url + "fitter_rates/"
        return this.http.get(url, this.httpOptions).map((data) => {
            return data;
        });
    }

    get_site_rates2(id) {
        this.getHeader();
        let url = this.url + "site_rates/" + id
        return this.http.get(url, this.httpOptions).map((data) => {
            return data;
        });
    }

    get_fitter_rates2(id) {
        this.getHeader();
        let url = this.url + "fitter_rates/" + id
        return this.http.get(url, this.httpOptions).map((data) => {
            return data;
        });
    }


    all(table) {
        this.getHeader();
        let url = this.apiUrl + '/all?_table=' + table;
        return this.http.get(url, this.httpOptions).map((data) => {
            return data;
        });
    }


    diaryFilter(table, fitter, developer, site) {
        this.getHeader();
        let url = this.apiUrl + '/all?' +
            '_table=' + table + '&' +
            'fitter=' + fitter + '&' +
            'developer=' + developer + '&' +
            'site=' + site;
        return this.http.get(url, this.httpOptions).map((data) => {
            return data;
        });
    }



    getPlots(site_id) {
        this.getHeader();
        let url = this.apiUrl + '/all?' +
            '_site_name=' + site_id;
        return this.http.get(url, this.httpOptions).map((data) => {
            return data;
        });
    }


    siteRates() {
        this.getHeader();
        return this.http.get(this.url + "site_rates", this.httpOptions).map((data) => {
            return data;
        });
    }

    fitterRates() {
        this.getHeader();
        return this.http.get(this.url + "fitter_rates", this.httpOptions).map((data) => {
            return data;
        });
    }

    getsiteName(id) {
        this.getHeader();
        return this.http.get(this.url + "getSiteName/" + id, this.httpOptions).map((data) => {
            return data;
        });
    }

    getfitterName(id) {
        this.getHeader();
        return this.http.get(this.url + "getFitterName/" + id, this.httpOptions).map((data) => {
            return data;
        });
    }

    getsiteRates() {
        this.getHeader();
        return this.http.get(this.url + "site_rates/", this.httpOptions).map((data) => {
            return data;
        });
    }

    getfitterRates() {
        this.getHeader();
        return this.http.get(this.url + "fitter_rates/", this.httpOptions).map((data) => {
            return data;
        });
    }

    getdataofDevelopers(id) {
        this.getHeader();
        return this.http.get(this.url + "developers/" + id, this.httpOptions).map((data) => {
            return data;
        });
    }

    addRecord(table, record): any {
        this.getHeader();
        const url = `${this.apiUrl}?_table=${table}`;
        console.log(record);
        return this.http.post(url, record, this.httpOptions).map((data) => {
            return data;

        });
    }

    addSalesSheet(record) {
        this.getHeader();
        const url = `${this.url}` + "sales";
        console.log(record);
        return this.http.post(url, record, this.httpOptions).map((data) => {
            return data;
        });
    }

    addSiteRate(site_id, fitter_id, type, prices) {
        this.getHeader();
        return this.http.post(this.url + "site_rates?site_id=" + site_id
            + "&fitter_id=" + fitter_id
            + "&type=" + type + "&prices=" + prices
            , this.httpOptions).map((data) => {
            return data;
        });
    }

    addfitterRate(fitter_id, type, prices) {
        this.getHeader();
        return this.http.post(this.url + "fitter_rates?fitter_id=" + fitter_id
            + "&type=" + type + "&prices=" + prices
            , this.httpOptions).map((data) => {
            return data;
        });
    }

    update(table, user) {
        this.getHeader();
        const url = `${this.apiUrl}/${user.id}?_table=${table}`;
        return this.http.put(url, user, this.httpOptions).map((data: any) => {
            return data;
        });
    }

    updatePrice(table, user, pricestr) {
        this.getHeader();
        return this.http.put(this.url + table + "/" + user + "?prices=" + pricestr, this.httpOptions).map((data: any) => {
            return data;
        });
    }

    updatesiteRatesPrice(table, user, pricestr) {
        this.getHeader();
        return this.http.put(this.url + table + "/" + user + "?prices=" + pricestr, this.httpOptions).map((data: any) => {
            return data;
        });
    }

    destroy(table, id) {
        this.getHeader();
        const url = `${this.apiUrl}/${id}?_table=${table}`;
        return this.http.delete(url, this.httpOptions).map((data: any) => {
            return data;
        });
    }

    delete(table, id) {
        this.getHeader();
        return this.http.delete(this.url + table + "/" + id, this.httpOptions).map((data: any) => {
            return data;
        });
    }

    getTableColumns(table_name) {
        this.getHeader();
        return this.http.get(this.apiUrl + '/' + table_name + '/columns', this.httpOptions).map((data: any) => {
            return data;
        });
    }

    show(table, id) {
        return this.http.get(this.apiUrl + '/' + id + '?_table=' + table, this.httpOptions).map((data: any) => {
            return data;
        });
    }

    logout() {
        this.getHeader();
        return this.http.post(this.url + 'logout', null, this.httpOptions).map(response => {
            return response;
        });
    }

    getJobSalesByIds(ids) {
        this.getHeader();
        let url = this.url + "getJobSalesByIds"
        return this.http.get(url + '?sale_ids=' + ids, this.httpOptions).map((data) => {
            return data;
        });
    }
}