import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {
  NbAuthComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import {ProfileComponent} from './pages/profile/profile.component';
import {AuthGuard} from './@guards/auth.guard';
import {NbLogoutComponent} from "./@core/logout/logout.component";
import {LoginComponent} from "./@core/login/login.component";


const routes: Routes = [
  {path: 'pages', loadChildren: 'app/pages/pages.module#PagesModule', canActivate: [AuthGuard]},
  

  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: 'profile',
     redirectTo:'/pages/profile'   
      },
      {
        path: 'login',
        component: LoginComponent,
      }
      , {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
 // {path: '**', redirectTo: '/pages/dashboard'},
 {path: '**', redirectTo: '/pages/sales-sheet'},
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
