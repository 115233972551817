import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {environment} from "../../../../environments/environment";
import {HttpHeaders} from "@angular/common/http";
import {NbAuthService} from "@nebular/auth";

@Component({
    selector: 'app-profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrls: ['./profile-picture.component.css']
})
export class ProfilePictureComponent implements OnInit {
    public uploader: FileUploader;
    private hasDragOver = false;
    private uploadUrl = environment.apiUrl + 'user/upload';

    @Input()
    private editmode = false;

    @Input()
    private url = '';

    @Output()
    private urlChange = new EventEmitter();

    httpOptions: {};

    constructor(private authService: NbAuthService) {
        let serviceToken = authService.getToken();
        if (serviceToken['value']) {
            let token = serviceToken['value']['token'];
            this.httpOptions = {
                headers: new HttpHeaders({Accpet: 'application/json', Authorization: 'Bearer ' + token})
            };
        }

        this.uploader = new FileUploader({
            url: this.uploadUrl,
            disableMultipart: false,
            autoUpload: true,
            // headers: this.httpOptions
        });

        this.uploader.response.subscribe(res => {
            this.url = 'http://localhost:9090/get/' + JSON.parse(res).id;
            this.urlChange.emit(this.url);
        });
    }

    public fileOver(e: any): void {
        this.hasDragOver = e;
    }

    ngOnInit() {
    }

}
