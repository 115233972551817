import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {HttpClient} from '@angular/common/http';
import {SalesSheet} from './sales';
const httpOptions = {
  // headers: new HttpHeaders({'Content-Type': 'application/json'})
};
@Injectable()
export class SalesService {
  constructor(private http: HttpClient) {
  }

  // private instance variable to hold base url
  private apiUrl = 'http://127.0.0.1:8000/api/sales';

  all(): Observable<any> {
    return this.http.get(this.apiUrl).map((data: any) => {
      return data;
    });
  }

 

  add(sales: SalesSheet) {
    return this.http.post(this.apiUrl, sales)
      .map((data: any) => {
        return data
      });
  }


  updateSales(sales: SalesSheet| number) {
    const id = typeof sales === 'number' ? sales : sales.id;
    const url = `${this.apiUrl}/${id}`;
    return this.http.put(url, sales, httpOptions).map((data: any) => {
      return data;
    });
  }


  removeSales(sales: SalesSheet | number) {
    const id = typeof sales === 'number' ? sales : sales.id;
    const url = `${this.apiUrl}/${id}`;
    return this.http.post<SalesSheet[]>(url, httpOptions).map((data: any) => {
      return data;
    });
  }

  removeSalesSheet(sales_sheet_id) {
    const id = sales_sheet_id
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<SalesSheet[]>(url, httpOptions).map((data: any) => {
      return data;
    });
  }
}
