import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router} from '@angular/router';
import {until} from "selenium-webdriver";
import elementIsSelected = until.elementIsSelected;


@Injectable()
export class RoleGuard implements CanActivateChild {
    constructor(private  router: Router) {
    }

    canActivateChild(route: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): boolean {
        return true;
        // let url = state.url;
        // if (url == '/pages/dashboard')return true;
        //
        // let roles = localStorage.getItem("roles");
        //
        // if (roles) {
        //     roles = JSON.parse(roles);
        //     let index = roles.indexOf(url);


            // if (index >= 0)
            //     return true;
            // else {
            //     return false;
            // }

        // }
        // return false;
    }
}
