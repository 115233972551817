import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'ngx-editor',
  templateUrl: 'editor.html',
})
export class EditorComponent implements OnInit {
 
  ngOnInit(): void {
    
  }
 
 

}
