import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {NbAuthService} from "@nebular/auth";


@Injectable()
export class FitterService {
  httpOptions = {};

  private apiUrl = environment.apiUrl + 'fitters';
  private apiUrl2 = environment.apiUrl ;
  jobsfitterId;

  constructor(private http: HttpClient) {
  }

  getHeader() {
    let token = localStorage.getItem('auth.token');
    this.httpOptions = {
      headers: new HttpHeaders({Accpet: 'application/json', Authorization: 'Bearer ' + token})
    }
  }


  all() {
    this.getHeader();
    let url = this.apiUrl;
    return this.http.get(url, this.httpOptions).map((data) => {
      return data;
    });
  }

  show(id) {
    this.getHeader();
    let url = this.apiUrl + '/' + id;
    return this.http.get(url, this.httpOptions).map((data) => {
      return data;
    });
  }


  update(record) {
    this.getHeader();
    let url = this.apiUrl + '/' + record.id;
    return this.http.put(url, record, this.httpOptions).map((data) => {
      return data;
    });
  }

  store(record) {
    this.getHeader();
    let url = this.apiUrl;
    return this.http.post(url, record, this.httpOptions).map((data) => {
      return data;
    });
  }

  getFitters(date) {
    this.getHeader();
    let url = this.apiUrl + '?date=' + date;
    return this.http.get(url, this.httpOptions).map(data => {
      return data;
    })
  }

  getRate_name() {
    this.getHeader();
    let url = this.apiUrl2 + "ratename";
    return this.http.get(url, this.httpOptions).map(data => {
      return data;
    })
  }


  getRatePrice(fitter_id,sale_sheet_id,job_type){
    this.getHeader();
    let url = this.apiUrl2 + "getRatePrice?"+
    "fitter_id="+fitter_id
    +"&sale_sheet_id="+sale_sheet_id
    +"&job_type="+job_type;
    return this.http.get(url, this.httpOptions).map(data => {
      return data;
    })
  }

  getRate_type() {
    this.getHeader();
    let url = this.apiUrl2 + "ratetype";
    return this.http.get(url, this.httpOptions).map(data => {
      return data;
    })
  }
}
