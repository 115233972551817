import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {NbAuthService} from "@nebular/auth";


@Injectable()
export class DeveloperService {
  httpOptions = {};

  private apiUrl = environment.apiUrl + 'developers';


  constructor(private http: HttpClient) {
  }

  getHeader() {
    let token = localStorage.getItem('auth.token');
    this.httpOptions = {
      headers: new HttpHeaders({Accpet: 'application/json', Authorization: 'Bearer ' + token})
    }
  }


  all() {
    this.getHeader();
    let url = this.apiUrl;
    return this.http.get<any[]>(url, this.httpOptions).map((data) => {
      return data;
    });
  }

  show(id) {
    this.getHeader();
    let url = this.apiUrl + '/' + id;
    return this.http.get(url, this.httpOptions).map((data) => {
      return data;
    });
  }


  update(record) {
    this.getHeader();
    let url = this.apiUrl + '/' + record.id;
    return this.http.put(url, record, this.httpOptions).map((data) => {
      return data;
    });
  }

  store(record) {
    this.getHeader();
    let url = this.apiUrl;
    return this.http.post(url, record, this.httpOptions).map((data) => {
      return data;
    });
  }

  addSite(id, site) {
    this.getHeader();
    let url = this.apiUrl + '/' + id + '/site';
    return this.http.post(url, site, this.httpOptions).map((data) => {
      return data;
    });
  }

  updateSite(site) {
    console.log(site);
    this.getHeader();
    let url = this.apiUrl + '/site/' + site.id;
    return this.http.put(url, site, this.httpOptions).map((data) => {
      return data;
    });
  }

  deleteSite(id) {
    this.getHeader();
    let url = this.apiUrl + '/site/' + id;
    return this.http.post(url, null, this.httpOptions).map((data) => {
      return data;
    });
  }
}
